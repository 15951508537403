
export default [
  {
    title: "Discover innovative ways to decorate",
    text:  `We provide unmatched quality, comfort, and style
            for property owners across the country. 
            Our experts combine form and function in
            bringing your vision to life. Create a room in your 
            own style with our collection and make
            your property a reflection of you and what you love.`
  },
  {
    title: "We are available all across the globe",
    text:  `With stores all over the world, it's easy for you to find
            furniture for your home or place of business. 
            Locally, we’re in most major cities throughout the country.
            Find the branch nearest you using our 
            store locator. Any questions? Don't hesitate to contact us today.`
  },
  {
    title: "Manufactured with the best materials",
    text:  `Our modern furniture store provide a high level of quality.
            Our company has invested in advanced technology 
            to ensure that every product is made as perfect and
            as consistentas possible. With three decades of 
            experience in this industry, we understand what
            customers want for their home and office.`
  }
];
